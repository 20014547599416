<template>
  <div>
    <div class="flex-row flex-wrap d-flex justify-content-start" v-if="fields">
      <template v-for="f in customFormFields">
        <div v-if="!f.role || $R(f.role)" class="flex-grow-0 mb-1 mr-3"
             :style="{width: f.width ? f.width * (fields[f.key] && fields[f.key].op === 'range' ? 1.8 : 1) + 'px' : 'auto'}" :key="f.key">
          <template v-if="fields[f.key]">
            <div class="clearfix" style="margin-bottom: 1px">
              <div class="pull-right ml-1">
                <b-badge class="pointer" :variant="getFieldVariant(f.key, 'eq')" @click="setFieldOp(f.key, 'eq')" v-b-tooltip.top="'값이 일치'">
                  =
                </b-badge>
                <!--<b-badge class="pointer" :variant="getFieldVariant(f.key, 'ne')" @click="setFieldOp(f.key, 'ne')" v-b-tooltip.top="'값이 불일치'">!=</b-badge>-->
                <b-badge v-if="f.type !== 'boolean' && f.enableEnum && statUri" class="pointer" :variant="getFieldVariant(f.key, 'enum')"
                         @click="setFieldOp(f.key, 'enum')" v-b-tooltip.top="'통계결과 Top 20 개의 값들 중에서 여러 개를 선택'">
                  <i class="fa fa-check-square-o"></i>
                </b-badge>
                <b-badge v-if="f.type !== 'boolean' && !f.disableRange" class="pointer" :variant="getFieldVariant(f.key, 'range')"
                         @click="setFieldOp(f.key, 'range')" v-b-tooltip.top="'값의 범위를 선택'">
                  <i class="fa fa-arrows-h"></i>
                </b-badge>
                <b-badge v-if="f.type !== 'number' && f.type !== 'boolean' && !f.disableLike" class="pointer" :variant="getFieldVariant(f.key, 'like')"
                         @click="setFieldOp(f.key, 'like')" v-b-tooltip.top="'중간값을 선택, 앞 뒤로 정확한 일치여부 선택 가능, 대소문자 무관, Elasticsearch 전용 정규식 사용 가능'">
                  <i class="fa fa-asterisk"></i>
                </b-badge>
                <b-badge v-if="f.enableExists" class="pointer" :variant="getFieldVariant(f.key, 'exists')" @click="setFieldOp(f.key, 'exists')"
                         v-b-tooltip.top="'값의 존재여부'">
                  <i class="fa fa-check"></i>
                </b-badge>
              </div>
              <small :title="f.key">{{f.name}}</small>
            </div>

            <!-- 일반 number, string 과 boolean 의 형태가 다르다 -->

            <template v-if="f.type === 'boolean' && fields[f.key].op === 'eq'">
              <b-btn-group class="d-flex">
                <!-- b-form-radio-group 을 이용할 수도 있으나 버튼별로 색을 달리하거나 click 이벤트 커스텀을 위해 btn-group 으로 작성한다.-->
                <b-btn v-for="o in [{text: '전체', value: ''}, ...(f.boolPreset || defaultBoolPreset)]" size="sm" :title="o.title" :key="o.text"
                       class="flex-grow-1" :variant="fields[f.key].value === o.value ? (o.variant || (o.text === '전체' ? 'dark' : 'success')): 'light'"
                       @click="o.click ? o.click() : fields[f.key].value = o.value">
                  {{ o.text }}
                </b-btn>
              </b-btn-group>
            </template>

            <template v-else>
              <b-input-group v-if="fields[f.key].op === 'eq' || fields[f.key].op === 'ne'" size="sm">
                <b-input-group-append>
                  <template v-if="statUri && f.enableStat">
                    <b-dropdown size="sm" variant="info" :ref="`dropdown.${f.key}`" @show="fieldStat(f.key, f.nested)" no-caret>
                      <template #button-content>
                        {{ fields[f.key].op === 'eq' ? '=' : '!=' }}
                      </template>
                      <b-dropdown-form class="py-2">
                        <template v-if="busy.stat">
                          <div class="text-center">
                            <b-spinner variant="primary"></b-spinner>
                          </div>
                        </template>
                        <template v-else-if="statData[f.key]">
                          총 {{statData[f.key].total}} 개
                          <i class="fa fa-refresh" @click="fieldStat(f.key, f.nested, true)"></i>
                          <hr/>
                          <template v-for="row in statData[f.key].rows">
                            <div :key="row.value">
                              <span :class="row.value === '(빈 문자열)' ? 'text-secondary' : ''" class="pointer" style="text-decoration: underline"
                                    @click="setFieldValue(f.key, row.value)">
                                {{ row.value }}
                              </span>: {{ row.count }} ({{ row.per }} %)
                            </div>
                          </template>
                          <hr/>
                          <template v-for="row in statData[f.key].etcRows">
                            <div :key="row.value"><span class="text-secondary">{{row.value}}</span>: {{row.count}} ({{row.per}} %)</div>
                          </template>
                        </template>
                        <template v-else>
                          <div class="text-center">
                            통계 데이터가 없습니다.
                          </div>
                        </template>
                      </b-dropdown-form>
                    </b-dropdown>
                  </template>
                  <b-button v-else disabled>{{ fields[f.key].op === 'eq' ? '=' : '!=' }}</b-button>
                </b-input-group-append>
                <b-form-input v-if="f.type === 'number'" class="text-center" :placeholder="f.placeholder || '입력값'"
                              v-model.number="fields[f.key].value" @keypress.enter="enter()"></b-form-input>
                <b-form-input v-else class="text-center" :placeholder="f.placeholder || '입력값'" v-model="fields[f.key].value"
                              @keypress.enter="enter()"></b-form-input>
              </b-input-group>

              <b-input-group v-if="fields[f.key].op === 'range'" size="sm">
                <b-form-input v-if="f.type === 'number'" class="text-center" placeholder="시작값" v-model.number="fields[f.key].gte"
                              @keypress.enter="enter()"></b-form-input>
                <b-form-input v-else class="text-center" placeholder="시작값" v-model="fields[f.key].gte" @keypress.enter="enter()"></b-form-input>
                <b-input-group-append>
                  <b-dropdown v-if="f.rangePreset" size="sm" variant="info" text="" ref="dropdown" class="" no-caret>
                    <template #button-content>
                      <i class="fa fa-arrows-h"></i>
                    </template>
                    <b-dropdown-item v-for="p in f.rangePreset" @click="setFieldRange(f.key, p)" :key="p.text">{{p.text}}</b-dropdown-item>
                  </b-dropdown>
                  <b-button v-else disabled><i class="fa fa-arrows-h"></i></b-button>
                </b-input-group-append>
                <b-form-input v-if="f.type === 'number'" class="text-center" placeholder="끝값" v-model.number="fields[f.key].lte"
                              @keypress.enter="enter()"></b-form-input>
                <b-form-input v-else class="text-center" placeholder="끝값" v-model="fields[f.key].lte" @keypress.enter="enter()"></b-form-input>
              </b-input-group>

              <b-input-group v-if="fields[f.key].op === 'like'" size="sm">
                <b-input-group-prepend>
                  <b-button :variant="fields[f.key].preLike ? 'info' : 'light'" title="하늘색으로 선택되었다면 앞부분에 임의의 값이 있는 값을 포함합니다"
                            @click="toggleFieldLike(f.key, 'pre')">
                    <i v-if="fields[f.key].preLike" class="fa fa-arrow-left"></i>
                    <i v-else class="fa fa-step-backward"></i>
                  </b-button>
                </b-input-group-prepend>
                <b-form-input v-if="f.type === 'number'" class="text-center" placeholder="중간검색" v-model.number="fields[f.key].value"
                              @keypress.enter="enter()"></b-form-input>
                <b-form-input v-else class="text-center" placeholder="중간검색" v-model="fields[f.key].value" @keypress.enter="enter()"></b-form-input>
                <b-input-group-append>
                  <b-button :variant="fields[f.key].postLike ? 'info' : 'light'" title="하늘색으로 선택되었다면 뒷부분에 임의의 값이 있는 값을 포함합니다"
                            @click="toggleFieldLike(f.key, 'post')">
                    <i v-if="fields[f.key].postLike" class="fa fa-arrow-right"></i>
                    <i v-else class="fa fa-step-forward"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </template>

            <enum-dropdown v-show="fields[f.key].op === 'enum'" class="w-100" v-model="fields[f.key].values" v-bind="{name: f.key, nested: f.nested, statUri}">

            </enum-dropdown>

            <b-input-group v-if="fields[f.key].op === 'exists'" size="sm">
                <b-button :variant="fields[f.key].exists ? 'success' : 'light'" class="w-50" size="sm" title=""
                          @click="toggleFieldExists(f.key)">
                  있음
                </b-button>
                <b-button :variant="fields[f.key].exists ? 'light' : 'warning'" class="w-50" size="sm" title=""
                          @click="toggleFieldExists(f.key)">
                  없음
                </b-button>
            </b-input-group>
          </template>
        </div>
      </template>
      <div v-if="!hideSetting" class="flex-grow-0 mt-1 mb-1 mr-3 pointer text-secondary d-flex align-items-center justify-content-center"
           @click="openFormFieldModal" style="width:47px; height:47px; border-radius: 5px; border: dashed 1px #ccc;" v-b-tooltip.bottom="'버튼을 눌러서 필드를 설정해 보세요'">
        <i class="fa fa-gear fa-2x"></i>
      </div>
    </div>

    <b-modal title="검색 필드 설정" v-model="modal.fields" ok-only ok-title="닫기" @hide="saveFormFields">
      <h6>선택된 필드별로 조건을 설정하여 검색할 수 있습니다.</h6>
      <div class="mb-2">
        <b-btn class="mr-1" size="sm" variant="success" @click="toggleFieldAll(true)">전체선택</b-btn>
        <b-btn size="sm" variant="warning" @click="toggleFieldAll(false)">전체해제</b-btn>
      </div>
      <b-form-checkbox-group v-model="_customFormFields" stacked>
        <template v-for="f in formFields">
          <h5 class="mt-3" v-if="f.group" :key="f.group">{{f.group}}</h5>
          <b-form-checkbox v-else-if="!f.role || $R(f.role)" class="mr-0" :value="f" :key="f.key">
            {{f.name}} ({{f.key}})
          </b-form-checkbox>
        </template>
      </b-form-checkbox-group>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import EnumDropdown from "./FormFieldsEnumDropdown";

export default {
  name: 'FormFields',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Object,
    name: String,
    statUri: String,
    defaultFields: {type: String, default: ''},
    formFields: Array,
    customFormFields: Array,
    hideSetting: {type: Boolean, default: false},
  },
  components: {EnumDropdown},
  data() {
    return {
      busy: {stat: false},
      modal: {fields: false},
      defaultBoolPreset: [
        {text: 'Y', value: true, title: 'Yes, True, 있음 등의 긍정값'},
        {text: 'N', value: false, variant: 'warning', title: 'No, False, 없음 등의 부정값'},
      ],
      statData: {},
    };
  },
  created() {
    /*
      FormFields 적용시, 다음 사항을 체크한다.

      // list()
      const fields = this.$refs.fields && this.$refs.fields.makeFieldsQuery() || [];

      // resetForm()
      const fields = this.form.list.fields;
      this.form.list = this.$utils.clone(this.defaultForm.list);
      Vue.set(this.form.list, 'fields', fields);
      this.$refs.fields.resetFieldValues();
     */
    this.initFormFields();
  },
  computed: {
    fields: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
    _customFormFields: {
      get() {
        return this.customFormFields;
      },
      set(v) {
        this.$emit('update:customFormFields', v);
      }
    }
  },
  methods: {
    getFieldVariant(key, op) {
      return this.fields[key].op === op ? 'info' : 'light';
    },
    setFieldOp(key, op) {
      this.fields[key].op = op;
      this.$forceUpdate();
    },
    setFieldRange(key, p) {
      this.fields[key].gte = p.gte;
      this.fields[key].lte = p.lte;
      this.$forceUpdate();
    },
    toggleFieldLike(key, type) {
      this.fields[key][`${type}Like`] = !this.fields[key][`${type}Like`];
      this.$forceUpdate();
    },
    toggleFieldExists(key) {
      this.fields[key].exists = !this.fields[key].exists;
      this.$forceUpdate();
    },
    async fieldStat(key, nested, refresh = false) {
      this.busy.stat = true;
      const j = await this.$api.postJson(this.statUri, {field: key, nested, refresh});
      this.busy.stat = false;
      if (j) {
        // 특수 문자 정비, 비중 계산
        const rows = [];
        const etcRows = [];
        j.rows.forEach(row => {
          row.value = row[key] === null ? '(null 값)' : row[key] === '' ? '(빈 문자열)' : row[key] === '(OTHER_COUNT)' ? '(나머지 순위)': row[key];
          row.per = this.$utils.round(row.count * 100 / j.total, 1);
          delete row[key];
          if (['(null 값)', '(나머지 순위)'].includes(row.value)) {
            etcRows.push(row);
          } else {
            rows.push(row);
          }
        });
        this.statData[key] = {total: j.total, rows, etcRows};
      }
    },
    setFieldValue(key, value) {
      this.fields[key].value = value === '(빈 문자열)' ? '' : value;
      this.$refs[`dropdown.${key}`][0] && this.$refs[`dropdown.${key}`][0].hide();
    },
    openFormFieldModal() {
      this.modal.fields = true;
    },
    toggleFieldAll(check) {
      if (check) this._customFormFields = this.formFields.filter(e => e.key).slice();
      else this._customFormFields.splice(0, this._customFormFields.length);
    },
    makeFieldsQuery() {
      return Object.entries(this.fields).filter(([, obj]) => {
        // 검색값이 있을 때만 전달
        return obj.op.in('eq', 'ne', 'like') && obj.value !== '' ||
          obj.op === 'enum' && obj.values.length > 0 ||
          obj.op === 'range' && (obj.gte !== '' || obj.lte !== '') ||
          obj.op === 'exists';
      }).map(([key, obj]) => {
        const field = this.formFields.find(e => e.key === key);
        return {key, ...obj, type: field.type, nested: field.nested};
      });
    },
    enter() {
      this.$emit('enter');
    },
    initFormFields() {
      for (const f of this.formFields.filter(e => e.key)) {
        // this.fields[f.key] = {op: f.op || 'eq', value: '', gte: '', lte: '', preLike: true, postLike: true};
        // 위처럼 하면 reactive 하게 바뀌지 않고 아래처럼 해줘야 한다.
        Vue.set(this.fields, f.key, {op: f.op || 'eq', value: '', gte: '', lte: '', values: [], preLike: true, postLike: true, exists: true});
      }

      let customFields = localStorage.getItem(this.name + '.CustomFormFields');
      if (!customFields && this.defaultFields) { // default
        customFields = this.defaultFields;
      }
      const fieldMap = this.$utils.arr2map(customFields.split(',').map(f => {
        const [key, op] = f.split(':');
        return {key, op};
      }), 'key', 'op');
      this._customFormFields.splice(0, this._customFormFields.length);
      for (const [key, op] of Object.entries(fieldMap)) {
        const field = this.formFields.find(e => e.key === key);
        if (field) {
          this._customFormFields.push(field);
          this.fields[key].op = op;
        }
      }
    },
    saveFormFields() {
      localStorage.setItem(this.name + '.CustomFormFields', this.customFormFields.map(e => `${e.key}:${this.fields[e.key].op}`).join(','));
    },
    resetFieldValues() {
      for (const v of Object.values(this.fields)) {
        // console.log(v); // 출력하면 값은 변해있지만 화면의 input 은 그대로이다.
        // Vue.set(v, 'value', ''); // 안됨
        // this.form.list.fields[k].value = ''; // 안됨
        // 위의 예시들이 안된 이유는 최초에 this.form.list.fields[k] = {} 를 할 때 Vue.set 을 사용해야 했기 때문이다.
        v.value = '';
        v.gte = '';
        v.lte = '';
        v.values = [];
        v.preLike = true;
        v.postLike = true;
        v.exists = true;
        if (v.op === 'exists') v.op = 'eq'; // op 가 exists 라면 true / false 로 값이 선택된 상황이다. 초기화해야 한다.
      }
    },
  }
}
</script>

<style scoped>

</style>
