<template>
  <b-dropdown size="sm" variant="light" @show="init()">
    <template #button-content>
      <template v-if="values && values.length === 0">
        전체 선택됨
      </template>
      <template v-else>
        {{values && values.length || '-'}} 개 선택됨({{$utils.round(TOP_VALUES.filter(e => values.includes(e.value)).sum('count') * 100 / totalCount, 1)}} %)
      </template>
    </template>
    <b-dropdown-form class="py-2" :style="{width: !$S.breakpoint.isLteXs ? '430px' : '300px'}">
      <b-button class="" size="sm" variant="primary" @click="toggleAll">전체</b-button>
      <b-btn class="ml-1" size="sm" @click="init(true)" :disabled="busy.init">
        <i class="fa fa-refresh"></i>
      </b-btn>
      <template v-if="busy.init">
        <div class="text-center">
          <b-spinner variant="primary"></b-spinner>
        </div>
      </template>
      <template v-else>
        <b-form-checkbox-group class="mt-2" name="group" v-model="values">
          <div class="flex-row flex-wrap d-flex justify-content-start">
            <div v-for="v in TOP_VALUES.filter(e => e.value !== null)" class="flex-grow-0 mb-1 mr-3" :key="v.value">
              <b-form-checkbox class="mr-0" :value="v.value">
                <div class="d-inline-block va-inherit ml-1" :title="`${v.count.comma()} (${v.per} %)`">{{ v.text }}</div>
              </b-form-checkbox>
            </div>
          </div>
        </b-form-checkbox-group>
        <hr/>
        <div v-if="otherCount > 0">기타 값이 {{otherCount.comma()}} 건({{$utils.round(otherCount * 100 / totalCount, 1)}} %) 존재합니다</div>
        <div v-if="nullCount > 0">Null 값이 {{nullCount.comma()}} 건({{$utils.round(nullCount * 100 / totalCount, 1)}} %) 존재합니다</div>
        <div v-if="nullCount > 0 || otherCount > 0" class="text-danger">체크박스가 모두 체크되어 있어도 전체 선택이 아닙니다.</div>
      </template>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
export default {
  name: 'FormFieldsEnumDropdown',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Array,
    name: String,
    nested: Boolean,
    statUri: String,
  },
  data() {
    return {
      TOP_VALUES: [],
      totalCount: 0,
      nullCount: 0,
      otherCount: 0,
      busy: {init: false},
      initCompleted: false,
    }
  },
  computed: {
    values: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {
    async init(refresh = false) {
      if (!refresh && (this.initCompleted || this.busy.init)) return;
      this.busy.init = true;
      const j = await this.$api.postJson(this.statUri, {field: this.name, size: 20, nested: this.nested, refresh});
      this.busy.init = false;
      if (j) {
        // 특수 문자 정비
        const rows = [];
        this.totalCount = j.total;
        j.rows.forEach(row => {
          const value = row[this.name];
          if (value == null) {
            this.nullCount = row.count;
            return;
          }
          if (value === '(OTHER_COUNT)') {
            this.otherCount = row.count;
            return;
          }
          const text = value === '' ? '(빈 문자열)' : value;
          rows.push({text, value, count: row.count, per: this.$utils.round(row.count * 100 / j.total, 1)});
        });
        this.TOP_VALUES = rows;
        // this.values = this.TOP_VALUES.map(e => e.value);
        this.initCompleted = true;
      }
    },
    toggleAll() {
      if (this.values.length === this.TOP_VALUES.length) {
        this.values = [];
      } else {
        this.values = this.TOP_VALUES.map(e => e.value);
      }
    }
  }
}
</script>
